import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import setupInterceptors from './services/setupInterceptors'
import Vuex from 'vuex'

setupInterceptors();

const store = new Vuex.Store({
	state: {
		loader: false,
	},
	mutations: {
		toggleLoader(state) {
			state.loader = !state.loader;
		},
	}
})

createApp(App)
.use(router)
.use(Vuex)
.use(store)
.mount('#app')

