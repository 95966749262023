import {createRouter, createWebHistory} from 'vue-router'
import ProfileView from '@/views/ProfileView';
import HomePage from '@/views/HomePage';
import SettingsView from "@/views/SettingsView";
import ChecksView from "@/views/ChecksView";

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomePage,
	},
	{
		path: '/profile',
		name: 'profile',
		component: ProfileView,
	},
	{
		path: '/checks',
		name: 'checks',
		component: ChecksView,
	},
	{
		path: '/settings',
		name: 'settings',
		component: SettingsView,
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
