<script>
import api from '@/services/api';
import moment from 'moment';
import {ref} from "vue";
import CheckView from "@/components/modals/CheckView";

export default{
    components: {
        CheckView,
    },
    data() {
        return {
            resultArr: [],
            page: 0,
            pageSize: 5,
            showCheck: ref(false),
            checkModel: {},
            totalElements: 0,
            showLoader: false,
            showEmpty: false,
        }
    },
    methods:{
        // getData() {
        //     api.get('/users')
        //         .then((res) => {
        //             if (res.data.success) {
        //                 this.userData = res.data.message;
        //             }
        //         })
        // },
        getData() {
            this.showLoader = true;
            api.get(`/receipts?size=${this.pageSize}&_page=${this.page++}`)
                .then((res) => {
                    this.totalElements = res.data.message.totalElements;
                    res.data.message.content.forEach((el) => {
                        this.resultArr.push(el);
                        window.addEventListener("scroll", this.handleScroll)
                    })
                    if (this.resultArr.length <= 0) {
                        this.showLoader = false;
                        this.showEmpty = true;
                    } else {
                        this.showLoader = true;
                        this.showEmpty = false;
                    }
                })
        },
        getDate(date) {
            return moment(date).format("YYYY-MM-DD")
        },
        handleScroll() {
            this.showLoader = true;
            let el = this.$refs.infiniteLoading;

            if (el.getBoundingClientRect().bottom <= document.documentElement.clientHeight) {
                window.removeEventListener("scroll", this.handleScroll)
                this.getData();
            }
        },
        openCheck(item) {
            this.showCheck = true;
            this.checkModel = item;
        },
    },
    // beforeMount() {
    //     window.addEventListener("scroll", this.handleScroll)
    // },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    },
    mounted() {
        this.getData();
    }
}
</script>


<template>
    <div class="flex w-full flex-col gap-4">
        <div class="flex w-full justify-between">
            <p class="text-gray-800 font-bold text-2xl text-left px-4">Чеки</p>
            <p class="text-gray-800 font-bold text-2xl text-left px-4">Всего: {{totalElements}}</p>
        </div>
        <div class="flex w-full flex-col gap-2 mb-[100px]">
            <div class="flex w-full rounded-3xl bg-gray-100 p-4 flex-col gap-6 shadow-md overflow-y-scroll ">
                <div class="flex w-full flex-col gap-6">
                    <div :key="item.postId"  v-for="item in resultArr" class="relative flex justify-between w-full bg-gray-200 rounded-3xl shadow-md px-4 pt-10 pb-4 sm:py-10 items-center flex-col sm:flex-row gap-5 sm:gap-0 overflow-hidden">
                        <div class="flex py-[6px] px-4 absolute top-0 left-0 bg-[#59499A] text-white font-bold rounded-[0_0_20px_0]">
                            {{item.dateTime}}
                        </div>
                        <div class="flex flex-col gap-1 text-left w-full sm:w-[initial]">
                            <p class="text-xl font-bold">PayGate Test</p>
                            <p class="font-bold text-[13px]">Покупка электронных денег</p>
                        </div>
                        <div class="flex items-center w-full sm:w-[initial] justify-end sm:justify-center">
                            <p class="font-bold text-4xl sm:text-3xl">100₸</p>
                        </div>
                        <div class="flex w-full sm:w-[initial] justify-end sm:justify-center">
                            <button @click="openCheck(item)" type="button" class="px-4 py-5 bg-[#6FCF97] hover:bg-[#6fcf978f] rounded-2xl text-white transition-all gap-1 flex shadow-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                                Просмотр чека
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex w-full justify-center" ref="infiniteLoading">
                    <div v-if="showLoader" class="loader"></div>
                    <p v-if="showEmpty" class="text-2xl font-bold">Чеков пока нет</p>
                </div>
            </div>
        </div>
    </div>
    <CheckView :show="showCheck" :model="checkModel" @close="showCheck = false"/>
</template>

<style scoped>
.loader {
    height: 30px;
    aspect-ratio: 2.5;
    --_g: no-repeat radial-gradient(farthest-side,#6FCF97  90%,#0000);
    background:var(--_g), var(--_g), var(--_g), var(--_g);
    background-size: 20% 50%;
    animation: l43 1s infinite linear;
}
@keyframes l43 {
    0%     {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
    16.67% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
    33.33% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
    50%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
    66.67% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
    83.33% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
    100%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
}

.overflow-y-scroll::-webkit-scrollbar{
    @apply w-[4px];
}

.overflow-y-scroll::-webkit-scrollbar-thumb{
    background: rgb(245,201,45);
    background: linear-gradient(180deg, rgba(245,201,45,0) 20%, rgba(111,207,151,1) 30%, rgba(111,207,151,1) 70%, rgba(247,213,90,0) 80%);
}
</style>