<script>
import LoginPart from '../components/parts/LoginPart.vue';
import SignupPart from '../components/parts/SignupPart.vue';
import PasswordResetPart from '../components/parts/PasswordResetPart.vue';

export default {
    components: {
        LoginPart,
        SignupPart,
        PasswordResetPart,
    },
    data() {
        return {
            currentComponent: 'login-part',
            val: null,
        }
    },
    methods:{
        goSignup(obj) {
            this.values = obj;
            this.currentComponent = 'signup-part';
        },
        goLogin() {
            this.currentComponent = 'login-part';
        },
        goSignupWithOTP(phone) {
            this.val = phone;
            this.currentComponent = 'signup-part';
            setTimeout(() => {
                this.$refs.authPart.otpIsSend();
            }, 100);
        },
        goPasswordReset() {
            this.currentComponent = 'password-reset-part';
        },
    },
}
</script>

<template>
    <div class="flex w-full h-[100vh] justify-center items-center ">
        <div class="flex w-full max-w-[448px] rounded-md border border-gray-300 p-6">
            <component
                ref="authPart"
                :val="val"
                @enterCabinet="$emit('enterCabinet')"
                @goSignup="goSignup"
                @goSignupWithOTP="goSignupWithOTP"
                @goLogin="goLogin()"
                @goPasswordReset="goPasswordReset()"
                :is="currentComponent"/>
        </div>
    </div>
</template>