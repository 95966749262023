<script setup>
import { ref, reactive } from 'vue';
import { defineProps } from 'vue';
import { defineEmits } from 'vue';
  
const props = defineProps({
    default: String,
  
    digitCount: {
        type: Number,
        required: true
    }
});
  
const digits = reactive([])
  
if (props.default && props.default.length === props.digitCount) {
    for (let i =0; i < props.digitCount; i++) {
        digits[i] = props.default.charAt(i)
    }
} else {
    for (let i =0; i < props.digitCount; i++) {
        digits[i] = null;
    }
}
  
const otpCont = ref(null)

const emit = defineEmits(['update:otp']);
  
const isDigitsFull = function () {
    for (const elem of digits) {
        if (elem == null || elem == undefined) {
            return false;
        }
    }
  
    return true;
}

const handleKeyDown = function (event, index) {
    if (event.key !== "Tab" && 
        event.key !== "ArrowRight" &&
        event.key !== "ArrowLeft"
    ) {
        event.preventDefault();
    }
      
    if (event.key === "Backspace") {
        digits[index] = null;
        
        if (index != 0) {
            (otpCont.value.children)[index-1].focus();
        } 

        return;
    }

    if ((new RegExp('^([0-9])$')).test(event.key)) {
        digits[index] = event.key;
  
        if (index != props.digitCount - 1) {
            (otpCont.value.children)[index+1].focus();
        }

        if (isDigitsFull()) {
            emit('update:otp', digits.join(''))
        }
    }
}
</script>

<template>
    <div ref="otpCont">
        <input
            autocomplete="one-time-code"
            type="text"
            class=""
            v-for="(el, ind) in digits"
            :key="el+ind"
            v-model="digits[ind]"
            :autofocus="ind === 0"
            placeholder=""
            maxlength="1"
            @keydown="handleKeyDown($event, ind)"
            :class="{notEmpty: digits[ind] !== null}"
        >
        <!-- <input
            type="text"
            autocomplete="one-time-code"
            inputmode="numeric"
            maxlength="6"
            pattern="\d{6}"> -->
    </div>
</template>
  
<style scoped>
input{
    @apply flex justify-center items-center w-10 h-10 text-center rounded-md bg-transparent border border-gray-400 text-xl transition-all focus-visible:outline-orange-600;
}
.notEmpty{
    @apply border-orange-600;
}
</style>