<script>
import api from '@/services/api';
import moment from 'moment';

export default{
    data() {
        return {
            userData: {
                phone: '',
                email: '',
                createdAt: '',
            },
        }
    },
    methods:{
        getData() {
            this.$store.commit('toggleLoader');
            api.get('/users')
                .then((res) => {
                    this.$store.commit('toggleLoader');
                    if (res.data.success) {
                        this.userData = res.data.message;
                    }
                })
        },
        getDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },
    mounted() {
        this.getData();
    },
}
</script>


<template>
    <div class="flex w-full flex-col gap-4">
        <p class="text-gray-800 font-bold text-2xl text-left px-4">Профиль</p>
        <div class="flex w-full flex-col gap-2">
            <div class="flex w-full rounded-3xl bg-gray-100 p-4 flex-col gap-2 shadow-md">
                <div class="flex w-full flex-col bg-gray-200 rounded-3xl p-4 gap-4">
                    <div class="flex w-full gap-2 items-start">
                        <div class="flex gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                            </svg>
                            <p>Номер телефона: </p>
                        </div>
                        <span class="font-bold">{{ userData.phone }}</span>
                    </div>
                    <div class="flex w-full gap-2 items-start">
                        <div class="flex gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />
                            </svg>
                            <p>E-mail: </p>
                        </div>
                        <span v-if="userData.email" class="font-bold">{{ userData.email }}</span>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="flex w-full gap-2 items-start">
                        <div class="flex gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <p>Дата регистрации: </p>
                        </div>
                        <span class="font-bold">{{ getDate(userData.createdAt) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>