<script>
import axios from 'axios';
import { vMaska } from "maska";

export default{
    directives: {
        maska: vMaska
    },
    emits:[
        'goSignup',
    ],
    data() {
        return {
            title: 'Вход',
            btnTitle: 'Войти',
            formData: {
                login: null,
                password: null,
                otp: null,
            },
            isGuest: null,
            sendDisabled: false,
            waitOTP: false,
            waitNewPassword: false,
            otpValue: 0,
            waitPassword: false,
            helpBlock: null,
            bindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            }
        }
    },
    methods:{
        sendForm() {
            this.sendDisabled = true;
            if (this.waitPassword) {
                this.sendLogin();
            } else {
                this.sendPhone();
            }
        },
        sendPhone() {
            axios.post(process.env.VUE_APP_BASE_URL + '/authorization/init', {
                "phone": '7' + this.bindedObject.unmasked,
            }).then((res) => {
                    if (res.data.success) {
                        if (res.data.message.nextStep == 'OTP') {
                            this.$emit('goSignupWithOTP', this.formData.login);
                        }
                        if (res.data.message.nextStep == 'PASSWORD') {
                            this.isGuest = false;
                            this.sendDisabled = false;
                            this.waitPassword = true;
                        }
                    }
                }).catch((res) => {
                    console.log('error');
                    console.log(res);
                })
        },
        sendLogin() {
            axios.post(process.env.VUE_APP_BASE_URL + '/authorization/login', {
                "phone": '7' + this.bindedObject.unmasked,
                "password": this.formData.password,
            }).then((res) => {
                console.log(res)
                if (res.data.success) {
                    localStorage.setItem('access_token', res.data.message.access_token);
                    localStorage.setItem('refresh_token', res.data.message.refresh_token);
                    
                    this.$router.go('/');
                } else {
                    if (res.data.error.message == 'INCORRECT_PASSWORD') {
                        this.helpBlock = 'Неверный логин или пароль';
                        this.sendDisabled = false;
                    }
                }
            })
        },
    },
}
</script>

<template>
    <div class="flex w-full flex-col gap-2">
        <p class="text-2xl font-normal text-black">{{ title }}</p>
        <div class="flex w-full rounded-md border border-gray-300">
            <img src="/img/Frame 13733 (2).png" alt="">
        </div>
        <form @submit.prevent="sendForm()" class="mt-2 w-full">
            <div class="relative z-0 w-full mb-6 group text-left">
                <input
                    v-maska="bindedObject"
                    data-maska="+7 (###)-###-##-##"
                    autocomplete="false"
                    v-model="formData.login"
                    type="text"
                    name="form-input-1"
                    id="form-input-1"
                    class="text-base block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required="">
                <label
                    for="form-input-1"
                    class="z-50 bg-white pr-2 text-base peer-focus:font-medium absolute text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Номер телефона
                </label>
            </div>
            <div v-if="isGuest == false" class="flex flex-col">
                <div class="relative z-0 w-full mb-6 group text-left">
                    <input
                        type="password"
                        v-model="formData.password"
                        name="form-input-2"
                        id="form-input-2"
                        class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required="">
                    <label
                        for="form-input-2"
                        class="z-50 bg-white pr-2 peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                        Пароль
                    </label>
                </div>
            </div>
            <div class="flex w-full justify-center mb-6">
                <button @click="$emit('goPasswordReset')" type="button" class="px-6 py-2 rounded-md hover:bg-gray-200 text-sm text-blue-500 font-semibold whitespace-nowrap transition-all">Забыли пароль?</button>
            </div>
            <p v-if="helpBlock" class="text-red-600 mb-6">{{ helpBlock }}</p>

            <div class="flex justify-between gap-2 items-center">
                <button @click="$emit('goSignup')" type="button" class="px-6 py-2 rounded-md hover:bg-gray-200 text-sm text-blue-500 font-semibold whitespace-nowrap transition-all">Создать кошелек</button>

                <button class="rounded-md bg-blue-500 text-white font-medium text-lg hover:bg-blue-600 transition-all px-6 py-1" :disabled="sendDisabled || !bindedObject.completed">{{ btnTitle }}</button>
            </div>
        </form>
    </div>
</template>

<style scoped>
button:disabled{
    @apply bg-gray-300 text-gray-700 cursor-not-allowed;
}
</style>