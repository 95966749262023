<script>
import api from '@/services/api';
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
    components: {
        CircleProgress,
    },
    data() {
        return {
            currency: '₸',
            nowBalance: 0,
            oneTimeLimit: 0,
            dayLimit: 0,
            userData: {},
        }
    },
    methods: {
        getData() {
            this.$store.commit('toggleLoader');
            api.get('/users').then((res) => {
                this.$store.commit('toggleLoader');
                if (res.data.success) {
                    this.userData = res.data.message;
                }
            })
        },
        getPercent(limit, current) {
            return current / limit * 100;
        },
    },
    mounted() {
        this.getData();
    },
}
</script>


<template>
    <div class="flex w-full flex-col gap-4">
        <p class="text-gray-800 font-bold text-2xl text-left px-4">Кошельки</p>
        <div v-if="userData" class="flex w-full flex-col gap-2">
            <div :key="wallet.id" v-for="wallet in userData.wallets"
                 class="flex w-full rounded-3xl bg-gray-100 p-4 flex-col gap-2 shadow-md">
                <p class="text-center font-bold">Кошелёк: {{ wallet.walletName }}</p>
                <div class="flex w-full flex-col bg-gray-200 rounded-3xl p-4">
                    <p class="text-left text-sm text-gray-700 font-semibold">Текущий баланс</p>
                    <p class="text-[40px] font-bold">{{ wallet.moneyAmount.toLocaleString('ru') }}
                        <span>{{ currency }}</span></p>
                </div>
                <div class="flex w-full flex-col bg-gray-200 rounded-3xl p-4">
                    <div class="flex flex-col gap-4 w-full">
                        <div class="flex w-full justify-start gap-4 items-center">
                            <div class="flex w-10 h-10">
                                <circle-progress
                                    :percent="100 - getPercent(userData.limits.monthLimit, userData.limits.alreadySpentMonthly)"
                                    :fill-color="'#59499A'"/>
                            </div>
                            <div class="flex w-full h-full flex-col items-start">
                                <p class="text-base ">Месячный лимит</p>
                                <p class="text-[14px] font-bold text-left">
                                    использовано
                                    {{ userData.limits.alreadySpentMonthly.toLocaleString('ru') }}
                                    {{ currency }}
                                    из
                                    {{ userData.limits.monthLimit.toLocaleString('ru') }}
                                    {{ currency }}</p>
                            </div>
                        </div>
                        <div class="flex w-full justify-start gap-4 items-center">
                            <div class="flex w-10 h-10">
                                <circle-progress
                                    :percent="100 - getPercent(userData.limits.dayLimit, userData.limits.alreadySpentDaily)"
                                    :fill-color="'#59499A'"/>
                            </div>
                            <div class="flex w-full h-full flex-col items-start">
                                <p class="text-base ">Суточный лимит</p>
                                <p class="text-[14px] font-bold text-left">использовано
                                    {{ userData.limits.alreadySpentDaily.toLocaleString('ru') }}{{ currency }} из
                                    {{ userData.limits.dayLimit.toLocaleString('ru') }}{{ currency }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>