<script>
import ModalBase from "@/components/modals/ModalBase";

export default {
    components: {
        ModalBase
    },
    props: [
        'show',
        'model',
    ],
}
</script>

<template>
    <ModalBase :show="show" @close="$emit('close')">
        <div class="flex w-full flex-col items-center gap-4">
            <svg width="146" height="24" viewBox="0 0 146 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 data-v-5ebe013c="">
                <path
                    d="M34.0002 12.8121V17.7316C33.9963 19.249 33.4025 20.7031 32.3487 21.7761C31.2949 22.8491 29.8667 23.4537 28.3764 23.4577H14.4188C12.9281 23.4548 11.4993 22.8506 10.4452 21.7774C9.39114 20.7042 8.79773 19.2494 8.79492 17.7316V12.1548C8.79773 10.637 9.39114 9.18221 10.4452 8.10899C11.4993 7.03576 12.9281 6.43157 14.4188 6.42871H27.2699V10.2064H14.4188C13.913 10.2104 13.4291 10.4167 13.0715 10.7808C12.7139 11.145 12.5112 11.6377 12.5073 12.1526V17.7316C12.5112 18.2465 12.7139 18.7393 13.0715 19.1034C13.4291 19.4675 13.913 19.6738 14.4188 19.6778H28.3764C28.8821 19.6738 29.3661 19.4675 29.7237 19.1034C30.0813 18.7393 30.2839 18.2465 30.2878 17.7316V16.2179H15.4849V12.8359C16.0583 12.8359 16.6247 12.828 17.184 12.8121H34.0002Z"
                    fill="#6FCF97" data-v-5ebe013c=""></path>
                <path
                    d="M2.90137 23.9914V5.7174C2.90642 4.20114 3.50082 2.74857 4.55464 1.67721C5.60847 0.605856 7.036 0.00284894 8.5252 0L28.3764 0C29.8667 0.00399547 31.2949 0.608557 32.3487 1.68153C33.4026 2.75451 33.9963 4.20864 34.0002 5.72605V10.2174H30.29V5.72605C30.2861 5.21112 30.0834 4.7184 29.7258 4.35428C29.3682 3.99016 28.8843 3.78384 28.3785 3.77989H8.5252C8.01946 3.78384 7.53554 3.99016 7.17792 4.35428C6.8203 4.7184 6.61767 5.21112 6.61378 5.72605V24L2.90137 23.9914Z"
                    fill="#59499A" data-v-5ebe013c=""></path>
                <path
                    d="M1.39322 2.09558V0.560268H1.69904L2.05584 1.64147C2.08982 1.7431 2.11319 1.81879 2.12805 1.85771C2.14504 1.80149 2.17265 1.71932 2.20876 1.6112L2.5698 0.542969H2.8374V2.07828H2.64626V0.811107L2.20239 2.09558H2.02186L1.58436 0.798133V2.09558H1.39322ZM0.49697 2.09558V0.74191H0V0.560268H1.1957V0.74191H0.696608V2.09558H0.49697Z"
                    fill="#312783" data-v-5ebe013c=""></path>
                <path
                    d="M74.9358 13.6115L68.9322 4.23084H72.9522L76.6813 10.4467L80.4721 4.23084H84.3775L78.374 13.5503V19.7225H74.9358V13.6115ZM64.3568 13.2356L62.2851 8.20867L60.2046 13.2356H64.3568ZM60.7424 4.11719H63.9161L70.6072 19.7225H66.9928L65.5646 16.2255H58.9528L57.5246 19.7225H53.9983L60.7424 4.11719ZM48.1711 12.0379C48.9048 12.0771 49.6253 11.8327 50.1811 11.356C50.4237 11.1355 50.6137 10.8642 50.7372 10.5617C50.8608 10.2592 50.9147 9.93323 50.8951 9.60747C50.8951 8.04257 49.9607 7.25574 48.1005 7.25574H45.4558V11.9942L48.1711 12.0379ZM42 4.23084H48.3826C49.9937 4.14132 51.576 4.68111 52.7905 5.73455C53.3206 6.23242 53.7367 6.83745 54.0105 7.50866C54.2842 8.17988 54.4094 8.9016 54.3774 9.62495C54.4127 10.4004 54.2635 11.1733 53.9419 11.881C53.6202 12.5887 53.1352 13.2115 52.5261 13.699C51.2466 14.6686 49.6653 15.1634 48.0564 15.0978H45.4117V19.7488H42.0088L42 4.23084Z"
                    fill="#59499A" data-v-5ebe013c=""></path>
                <path
                    d="M134.125 4.23134H145.912V7.23001H137.537V10.3773H144.889V13.411H137.519V16.6632H146V19.6968H134.125V4.23134ZM123.29 7.3699H118.539V4.23134H131.489V7.3699H126.729V19.723H123.29V7.3699ZM113.002 13.2361L110.931 8.20917L108.85 13.2361H113.002ZM109.388 4.11769H112.553L119.253 19.723H115.656L114.228 16.226H107.625L106.197 19.723H102.67L109.388 4.11769ZM94.0484 19.9941C92.9542 20.0359 91.8628 19.856 90.8412 19.4651C89.8195 19.0743 88.889 18.4808 88.1066 17.721C86.6422 16.1854 85.8262 14.152 85.8262 12.0384C85.8262 9.9248 86.6422 7.89141 88.1066 6.35576C88.87 5.58504 89.7853 4.97848 90.7954 4.57387C91.8056 4.16927 92.8889 3.97529 93.9779 4.00403C96.2281 3.93763 98.4243 4.69371 100.149 6.12846L97.9803 8.7512C96.856 7.75499 95.4061 7.19601 93.8986 7.17756C93.2959 7.17104 92.6992 7.29611 92.1507 7.5439C91.6022 7.79168 91.1154 8.15611 90.7249 8.61133C89.8575 9.57304 89.378 10.8181 89.378 12.1083C89.378 13.3986 89.8575 14.6436 90.7249 15.6053C91.1566 16.0654 91.6833 16.4277 92.269 16.6675C92.8547 16.9074 93.4857 17.0192 94.119 16.9954C95.2698 17.0392 96.4043 16.7143 97.3544 16.0687V13.8481H93.9338V10.9106H100.722V17.6336C98.8623 19.2013 96.4888 20.0408 94.0484 19.9941Z"
                    fill="#6FCF97" data-v-5ebe013c=""></path>
            </svg>

            <hr class="bg-black h-[1px] w-full">

            <div class="flex w-full flex-col gap-2">
                <div class="flex w-full justify-between">
                    <p class="font-bold">{{ model.id }}</p>
                    <p class="text-right max-w-[150px]">{{ model.dateTime }}</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Итого</p>
                    <p class="text-right max-w-[150px]">{{ model.totalValue }}₸</p>
                </div>
            </div>

            <hr class="bg-black h-[1px] w-full">

            <div class="flex w-full flex-col gap-2">
                <div class="flex w-full justify-between">
                    <p class="font-bold">Услуга</p>
                    <p class="text-right max-w-[150px]">Покупка электронных денег</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Сервис</p>
                    <p class="text-right max-w-[150px]">{{ model.serviceName }}</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Отправитель</p>
                    <p class="text-right max-w-[150px]">{{ model.consumer }}</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Сумма</p>
                    <p class="text-right max-w-[150px]">{{ model.serviceCost }}</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Комиссия</p>
                    <p class="text-right max-w-[150px]">{{ model.topFee }}</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Номер транзакции</p>
                    <p class="text-right max-w-[150px]">{{ model.numericTransactionId }}</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Идентификатор операции</p>
                    <p class="text-right max-w-[150px]">{{ model.transactionId }}</p>
                </div>
            </div>

            <hr class="bg-black h-[1px] w-full">

            <div class="flex w-full flex-col gap-2">
                <div class="flex w-full justify-between">
                    <p class="font-bold">Оператор ЭД</p>
                    <p class="text-right max-w-[150px]">ТОО "PayGate (ПэйГейт)"<br>210440011954</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Эмитент</p>
                    <p class="text-right max-w-[150px]">АО "Народный банк<br> Казахстана" 911040000021</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Поставщик услуг</p>
                    <p class="text-right max-w-[150px]">ТОО «PayGate (ПэйГейт)»<br>210440011954</p>
                </div>
                <div class="flex w-full justify-between">
                    <p class="font-bold">Служба поддержки</p>
                    <p class="text-right max-w-[150px]">+7 (727) 355 96 77<br><a class="underline" href="mailto:support@paygate.kz">support@paygate.kz</a></p>
                </div>
            </div>

            <hr class="bg-black h-[1px] w-full">

            <div class="flex w-full justify-center">
                <p>{{ model.customText }}</p>
            </div>
        </div>
    </ModalBase>
</template>