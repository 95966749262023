<script>
import { ref } from 'vue';
import otp from './OTPInput.vue';
import axios from 'axios';
import { vMaska } from "maska";


export default{
    directives: {
        maska: vMaska
    },
    components:{
        otp,
    },
    props:[
        'val',
    ],
    data() {
        return {
            formData: {
                login: null,
                password: null,
            },
            inputData: ref(null),
            otpShow: false,
            sendDisabled: false,
            btnTitle: 'Продолжить',
            otpIsSendByLogin: false,
            passwordShow: false,
            sessionId: null,
            helpBlock: null,
            bindedObject: {
                masked: "",
                unmasked: "",
                completed: false
            }
        }
    },
    methods:{
        otpIsSend() {
            this.otpShow = true;
            this.otpIsSendByLogin = true;
        },
        sendOtp(event) {
            axios.post(process.env.VUE_APP_BASE_URL + '/authorization/otp-check', {
                "phone": '7' + this.bindedObject.unmasked,
                "otp": event
            }).then((res) => {
                this.sendDisabled = false;
                if (res.data.success) {
                    if (res.data.message.nextStep == 'NEW_PASSWORD') {
                        this.otpShow = false;
                        this.passwordShow = true;
                        this.sessionId = res.data.message.sessionId;

                        this.helpBlock = null;
                    }
                } else {
                    this.helpBlock = 'Проверьте правильность кода';
                }
            }).catch((res) => {
                console.log(res);
            })
        },
        setNewPassword() {
            localStorage.setItem('token', '123');
            this.$router.go('/');
        },
        sendForm() {
            this.sendDisabled = true;
            if (this.passwordShow) {
                axios.post(process.env.VUE_APP_BASE_URL + '/authorization/new-password', {
                    "phone": '7' + this.bindedObject.unmasked,
                    "sessionId": this.sessionId,
                    "password": this.formData.password,
                }).then((res) => {
                    if (res.data.success) {
                        localStorage.setItem('access_token', res.data.message.access_token);
                        localStorage.setItem('refresh_token', res.data.message.refresh_token);
                        this.sendDisabled = false;
                        
                        this.$router.go('/');
                    } else {
                        alert(res.data.message);
                        this.$router.go('/');
                    }
                }).catch((res) => {
                    console.log(res);
                    alert('error');
                })
            } else {
                if (!this.otpIsSendByLogin) {
                    axios.post(process.env.VUE_APP_BASE_URL + '/authorization/reset-password', {
                        "phone": '7' + this.bindedObject.unmasked,
                    }).then((res) => {
                            if (res.data.success) {
                                if (res.data.message.nextStep == 'OTP') {
                                    this.otpShow = true;
                                    this.sendDisabled = false;
                                }
                                if (res.data.message.nextStep == 'PASSWORD') {
                                    // this.$emit('goLogin');
                                }
                            }
                        }).catch((res) => {
                            console.log('error');
                            console.log(res);
                        })
                }
            }
        },
    },
    mounted() {
        this.formData.login = this.val;
    }
}
</script>


<template>
    <div class="flex w-full flex-col gap-2">
        <p class="text-2xl font-normal text-black">Восстановление пароля</p>
        <div class="flex w-full rounded-md border border-gray-300">
            <img src="/img/Frame 13733 (2).png" alt="">
        </div>
        <form @submit.prevent="sendForm()" class="mt-2 w-full">
            <div class="relative z-0 w-full mb-6 group text-left">
                <input
                    v-maska="bindedObject"
                    data-maska="+7 (###)-###-##-##"
                    autocomplete="false"
                    v-model="formData.login"
                    type="text"
                    name="form-input-1"
                    id="form-input-1"
                    class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required="">
                <label
                    for="form-input-1"
                    class="z-50 bg-white pr-2 peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Номер телефона
                </label>
            </div>

            <div v-if="otpShow" class="flex flex-col gap-2 mb-6">
                <p>Введите код из смс</p>
                <otp
                    ref="otpRef"
                    class="flex w-full gap-2 justify-center items-center"
                    :digit-count="6"
                    @update:otp="sendOtp($event)" />
            </div>

            <p v-if="helpBlock" class="text-red-600 mb-6">{{ helpBlock }}</p>

            <div v-if="passwordShow" class="relative z-0 w-full mb-6 group text-left">
                <input
                    minlength="8"
                    type="password"
                    v-model="formData.password"
                    name="form-input-2"
                    id="form-input-2"
                    class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required="">
                <label
                    for="form-input-2"
                    class="z-50 bg-white pr-2 peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                    Придумайте пароль
                </label>
            </div>

            <div class="flex gap-2 items-center justify-between">
                <button v-if="isGuest != false" @click="$emit('goLogin')" type="button" class="px-6 py-2 rounded-md hover:bg-gray-200 text-sm text-blue-500 font-semibold whitespace-nowrap transition-all">Уже есть кошелек?</button>

                <button v-if="!otpShow" class="rounded-md bg-blue-500 text-white font-medium text-lg hover:bg-blue-600 transition-all px-6 py-1" :disabled="sendDisabled || !bindedObject.completed">{{ btnTitle }}</button>
            </div>
        </form>
    </div>
</template>

<style scoped>
button:disabled{
    @apply bg-gray-300 text-gray-700 cursor-not-allowed;
}
</style>