import axiosInstance from "./api";
import axios from "axios";
import router from "@/router";

const setup = () => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('access_token');
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        
        async (err) => {
        const originalConfig = err.config;

        if (err.response.status === 401 || err.response.status === 302 && !originalConfig._retry) {    
            const now = Date.now();
            const refreshToken = localStorage.getItem('refresh_token');

            if (refreshToken) {
                if (now <= localStorage.getItem('refresh_exp')) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axios.post(process.env.VUE_APP_BASE_URL + "/authorization/refresh", {
                            refreshToken: localStorage.getItem('refresh_token')
                        }).catch(() => {
                            localStorage.clear();
                            router.go();
                        });

                        const accessToken = rs.data.data.access_token;
                        localStorage.setItem('access_token', accessToken);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                } else {
                    localStorage.clear();
                    router.go();
                }
            } else {
                localStorage.clear();
                router.go();
            }
        }

        return Promise.reject(err);
    });
}

export default setup;