<script>
import BottomNav from '@/components/BottomNav';

export default {
    components: {
        BottomNav,
    }
}
</script>

<template>

    <div class="flex w-full flex-col items-center m-auto max-w-screen-md p-2">
        <router-view/>
    </div>
    <BottomNav/>
</template>