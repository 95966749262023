<script>
import api from '@/services/api';
import moment from 'moment';

export default{
    data() {
        return {
            userData: {
                phone: '',
                email: '',
                createdAt: '',
            },
        }
    },
    methods:{
        getData() {
            api.get('/users')
                .then((res) => {
                    if (res.data.success) {
                        this.userData = res.data.message;
                    }
                })
        },
        getDate(date) {
            return moment(date).format("YYYY-MM-DD")
        },
        logout() {
            api.get(process.env.VUE_APP_BASE_URL + '/authorization/logout')
                .then(() => {
                    localStorage.clear();
                    this.$router.go();
                })
        }
    },
    mounted() {
        this.getData();
    },
}
</script>


<template>
    <div class="flex w-full flex-col gap-4">
        <p class="text-gray-800 font-bold text-2xl text-left px-4">Настройки</p>
        <div class="flex w-full flex-col gap-2">
            <div class="flex w-full rounded-3xl bg-gray-100 p-4 flex-col gap-6 shadow-md">
                <div class="flex w-full justify-end">
                    <button @click="logout()" type="button" class="flex gap-2 bg-red-600 text-white px-3 py-3 rounded-3xl hover:bg-red-400 transition-all shadow-md">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                        </svg>
                        Выйти из аккаунта
                    </button>
                </div>
                <div class="flex w-full bg-gray-200 h-[299px] rounded-3xl"></div>
            </div>
        </div>
    </div>
</template>